import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { ItemDiamondStyle } from './styles'
import { formatPathURL } from '../../../utils/sanitization'

export default ({ icon, title, link }) => {
    const linkSanatized = formatPathURL(link)
    return (
        <ItemDiamondStyle>
            <Link to={linkSanatized} className="linkWithoutEffect">
                <div className="categoriesDiamond">
                    <Img className="categoriesIcon" fixed={icon.data} alt={icon.alt} />
                </div>

                <p className="font-white">{title} </p>
            </Link>
        </ItemDiamondStyle>
    )
}
