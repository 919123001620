import React from 'react'
import { Link } from 'gatsby'
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    Image,
    DotGroup,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from 'react-icons/io'

import useWindowSize from '../../hooks/useWindowSize'

import { BannerCarouselStyle, SampleCarouselStyle, CatalogCarouselStyle } from './style'

export const BannerCarousel = ({ data }) => {
    const isSmallScreen = useWindowSize() < 798

    return (
        !!data?.length && (
            <BannerCarouselStyle>
                <CarouselProvider
                    naturalSlideWidth={isSmallScreen ? 200 : 220}
                    naturalSlideHeight={isSmallScreen ? 50 : 70}
                    isPlaying
                    interval={4000}
                    infinite={true}
                    totalSlides={data.length}
                >
                    <Slider>
                        {data.map((item, index) => {
                            return (
                                <a
                                    key={index}
                                    href={item.link_banner || '#'}
                                    target={item.link_banner ? '_BLANK' : ''}
                                    rel="noopener noreferrer"
                                >
                                    <Slide index={index}>
                                        <div className="carouselBannerContent">
                                            {!isSmallScreen && item.title && (
                                                <div className="carrousel-item-content">
                                                    <h2 className="font-white font-title">
                                                        {item.title}
                                                    </h2>
                                                </div>
                                            )}

                                            {!isSmallScreen && item.description && (
                                                <div className="carrousel-item-content">
                                                    <p className="font-white">{item.description}</p>
                                                </div>
                                            )}
                                        </div>

                                        <Image
                                            src={item.image?.publicURL}
                                            className="imageBackgroundCarrousel"
                                        />
                                    </Slide>
                                </a>
                            )
                        })}
                    </Slider>

                    <DotGroup className="carousel-dotGroup" showAsSelectedForCurrentSlideOnly />
                </CarouselProvider>
            </BannerCarouselStyle>
        )
    )
}

export const SampleCarousel = ({ filterProduct, data }) => {
    const isSmallScreen = useWindowSize() < 798

    return (
        data?.length && (
            <SampleCarouselStyle>
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    isPlaying
                    interval={2000}
                    infinite={true}
                    visibleSlides={
                        isSmallScreen > 798
                            ? Math.ceil(data.length / 2)
                            : Math.ceil(data.length / 4)
                    }
                    totalSlides={data.length}
                    isIntrinsicHeight
                >
                    <Slider>
                        {data.map((i) => {
                            return (
                                i.logoFilter && (
                                    <Slide key={i.id} index={data.indexOf(i)}>
                                        <Link to={`/produtos`} state={{ brandSelected: i.name }}>
                                            <Image
                                                src={i.logoFilter?.childImageSharp.fixed.src}
                                                alt={`${i.name} - Marca Minas Coding`}
                                            />
                                        </Link>
                                    </Slide>
                                )
                            )
                        })}
                    </Slider>

                    {data.length && (
                        <ButtonBack className="carouselButtonBack font-white" style={{ left: -40 }}>
                            <IoIosArrowDropleftCircle className="carouselIcon" />
                        </ButtonBack>
                    )}
                    {data.length && (
                        <ButtonNext
                            className="carouselButtonNext font-white"
                            style={{ right: -40 }}
                        >
                            <IoIosArrowDroprightCircle className="carouselIcon" />
                        </ButtonNext>
                    )}
                </CarouselProvider>
            </SampleCarouselStyle>
        )
    )
}

export const CatalogCarousel = ({ data }) => {
    const dataLenght = data?.length || 3
    return (
        <CatalogCarouselStyle>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={50}
                isPlaying
                interval={4000}
                infinite={true}
                visibleSlides={1}
                totalSlides={dataLenght}
                isIntrinsicHeight
            >
                <Slider>
                    {data.map((i) => {
                        return (
                            <Slide key={i.id} index={i.id}>
                                <Image
                                    className="catalogCarouselItem"
                                    alt={i.name}
                                    src={`${i.url}`}
                                />
                            </Slide>
                        )
                    })}
                </Slider>
                {dataLenght > 1 && (
                    <>
                        <ButtonBack className="carouselButtonBack font-white" style={{ left: -40 }}>
                            <IoIosArrowDropleftCircle className="carouselIcon" />
                        </ButtonBack>

                        <ButtonNext
                            className="carouselButtonNext font-white"
                            style={{ right: -40 }}
                        >
                            <IoIosArrowDroprightCircle className="carouselIcon" />
                        </ButtonNext>
                    </>
                )}
                {dataLenght > 1 && (
                    <DotGroup
                        className="carousel-dotGroup"
                        showAsSelectedForCurrentSlideOnly={true}
                    />
                )}
            </CarouselProvider>
        </CatalogCarouselStyle>
    )
}
