import styled from 'styled-components'

import ecoBackground from '../../../images/background/ecoIndustryBackground2.jpg'

export const ContainerProduct = styled.section`
    text-align: center;
    height: auto;
    padding: 2vh;

    .card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
`
export const ContainerCategories = styled.section`
    display: flex;
    flex-direction: column;

    background-color: #d32327;

    .wrapperCategories {
        padding: 2vh;
    }

    .categoriesTop {
        text-align: center;
        h1 {
            margin-bottom: -3px;
        }
    }

    .categoriesContent {
        margin: 40px 0 0px;

        .categoriesItem {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            align-items: center;
        }
    }
`

export const ContainerRegions = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    padding: 4vh 0;

    ::before {
        opacity: 0.9;
    }

    .wrapperRegions {
        align-self: center;
        padding: 4vh 2vh;
        width: 80vw;

        .region-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .regionsMapRight,
            .regionsMainCityLeft {
                order: 2;
            }

            .regionsMapLeft,
            .regionsMainCityRight {
                order: 1;
            }

            .regionsMap {
                flex-basis: 40%;
                box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
            }

            .regionsMainCities {
                flex-basis: 60%;

                .regionsTitleMainCity {
                    text-align: center;
                    .regionsArrow {
                        font-size: 15px;
                    }
                }

                .regionsTableMainCity {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;

                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                    ul {
                        flex-basis: 30%;
                        li {
                            list-style-type: circle;
                            margin-bottom: 20px;
                        }
                    }
                }

                .regionsViewAllCityL {
                    text-align: left;
                    float: left;
                }
                .regionsViewAllCityR {
                    text-align: right;
                    float: right;
                }

                .regionsViewAllCity {
                    margin-top: 20px;
                    width: auto;
                    background: linear-gradient(to bottom, #d32327 0%, #db3235 100%);
                    background-position: 0 100%;
                    background-repeat: repeat-x;
                    background-size: 2px 4px;
                    text-decoration: none;
                    transition: all 0.6s;
                    padding: 5px;
                    cursor: pointer;
                    :hover {
                        cursor: pointer;
                        background-size: 4px 55px;
                        color: #ffffff;
                    }
                }
            }

            @media only screen and (max-width: 998px) {
                .regionsMap,
                .regionsMainCities {
                    min-width: 250px;
                    flex-basis: 100%;
                }

                .regionsMainCities {
                    margin-top: 30px;
                    justify-content: center !important;
                }

                .regionsMapRight,
                .regionsMapLeft {
                    order: 0;
                }

                .regionsMainCityRight,
                .regionsMainCityLeft {
                    order: 1;
                }

                .regionsTableMainCity {
                    justify-content: center !important;
                    text-align: center !important;
                    ul {
                        li {
                            list-style-type: none !important;
                        }
                    }
                }

                .regionsViewAllCityL,
                .regionsViewAllCityR {
                    text-align: center !important;
                }
            }
        }
        .regionsDivider {
            margin: 30px auto;
            width: 60vw;
        }
    }
`

export const ContainerEnvironmental = styled.section`
    background-image: url(${ecoBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 3vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    height: 450px;

    .wrapperContentEnvironmental {
        padding: 5px 20px;
        background: rgba(211, 35, 39, 0.9);
    }

    .dimensionsTitleEnvironmental {
        align-self: center;
        width: auto;
        margin-bottom: 50px;
    }

    .wrapperIndicativesEnvironmental {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .indicativeEnvironmental {
            flex-basis: 25%;
            align-self: center;
            .iconEnvironmental {
                width: 80px;
                margin-bottom: 20px;
            }
            .dimensionsItemEnvironmental {
                width: 20vw;
                max-width: 300px;
                margin: 0 auto;
            }
        }
    }

    .dividerEnvironmental {
        border-color: rgba(211, 35, 39, 0.71);
        margin-bottom: 20px;
    }

    .btnEnvironmental {
        align-self: center;
        text-transform: uppercase;
        font-size: 14px;
        width: 40vw;
        max-width: 300px;
    }

    @media only screen and (max-width: 882px) {
        .indicativeEnvironmental {
            flex-basis: 50% !important;
            margin-bottom: 30px;
            .dimensionsItemEnvironmental {
                width: 45vw !important;
                margin: 0 auto;
            }
        }
    }
`
