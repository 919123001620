import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import Layout from '../layout'
import SEO from '../utils/seo'

import { ContainerCategories, ContainerRegions } from '../assets/styles/pages/home'

import { BannerCarousel } from '../components/carousels'
import BannerDivider from '../components/bannerDivider'

import ItemDiamond from '../components/homePage/diamondItem'

import Divider from '../components/Divider'

const IndexPage = () => {
    const { bannerData, sectors, regionsAttendance } = useStaticQuery(graphql`
        query {
            bannerData: allStrapiBanners {
                nodes {
                    id
                    title
                    description
                    name_button
                    link_banner
                    image {
                        publicURL
                    }
                }
            }

            productsAndServices: allStrapiProdutosEServicos {
                nodes {
                    title
                    link
                    description
                    id
                    image {
                        childImageSharp {
                            fixed(quality: 100) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }

            sectors: allStrapiSetor {
                nodes {
                    categories {
                        id
                        title
                        icon {
                            childImageSharp {
                                fixed(quality: 100) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }

            regionsAttendance: allStrapiMatrizEFiliais {
                nodes {
                    id
                    name
                    regions_attendance
                    maps_link
                }
            }
        }
    `)

    const splitRegionsIntoChunk = (arrayData, perChunk) => {
        var result = arrayData?.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])

        return result
    }

    return (
        <Layout>
            <SEO title="Home" />

            <BannerCarousel data={bannerData?.nodes} />

            <BannerDivider title="Minas Coding" subtitle="Compromisso com a qualidade" />

            {/* {!!productsAndServices?.nodes.length && (
                <ContainerProduct>
                    <div className="col-10">
                        <Divider size={3} orientation="horizontal" />

                        <h1 className="font-subtitle">Produtos e Serviços</h1>

                        <Divider size={4} orientation="horizontal" />

                        <div className="card-container">
                            {productsAndServices?.nodes.map((item, index) => (
                                <CardSimple
                                    key={index}
                                    img={{
                                        data: item.image?.childImageSharp?.fixed,
                                        alt: item.title,
                                    }}
                                    title={item.title}
                                    message={item.description}
                                    btnText={'Confira aqui'}
                                    btnLink={item.link}
                                />
                            ))}
                        </div>

                        <Divider size={3} orientation="horizontal" />
                    </div>
                </ContainerProduct>
            )} */}

            {!!sectors?.nodes.length && (
                <ContainerCategories>
                    <div className="wrapperCategories">
                        <div className="categoriesTop">
                            <h1 className="font-white font-subtitle mtb-1">Como podemos ajudar?</h1>
                            <p className="font-white">Escolha o setor da sua empresa</p>
                        </div>

                        <div className="categoriesContent">
                            <div className="categoriesItem">
                                {sectors.nodes[0].categories.map((sector, index) => (
                                    <ItemDiamond
                                        key={index}
                                        icon={{
                                            data: sector.icon?.childImageSharp?.fixed,
                                            alt: sector.title,
                                        }}
                                        title={sector.title}
                                        link={`solucoes/setor/${sector.title}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="bottom-arrow"></div>
                </ContainerCategories>
            )}

            <ContainerRegions>
                <Divider size={3} orientation="horizontal" />
                <h1 className="font-subtitle">Regiões de Atendimento</h1>

                <Divider size={1} orientation="horizontal" />

                {regionsAttendance?.nodes.length && (
                    <div className="wrapperRegions">
                        {regionsAttendance?.nodes.map((region, index) => {
                            const regionsAttendance = region?.regions_attendance.split(',')

                            const regionsChunk = splitRegionsIntoChunk(regionsAttendance, 4)

                            const indexIsEven = +index % 2 === 0

                            return (
                                <React.Fragment key={index}>
                                    <div className="region-item mt-4">
                                        <div className="regionsMainCities regionsMainCityLeft">
                                            <h3 className="font-subtitle regionsTitleMainCity">
                                                {region.name}
                                            </h3>

                                            <div
                                                className="regionsTableMainCity"
                                                style={{ justifyContent: 'flex-end' }}
                                            >
                                                {regionsChunk.map((chunk) => (
                                                    <ul key={index}>
                                                        {chunk.map((city, index) => (
                                                            <li>{city}</li>
                                                        ))}
                                                    </ul>
                                                ))}
                                            </div>

                                            <Link to="/regioes">
                                                <h3
                                                    className={`font-dark regionsViewAllCity ${
                                                        indexIsEven
                                                            ? 'regionsViewAllCityR'
                                                            : 'regionsViewAllCityL'
                                                    }`}
                                                >
                                                    Ver todas as cidades
                                                </h3>
                                            </Link>
                                        </div>

                                        <iframe
                                            title="regionsBhFrame"
                                            src={region.maps_link}
                                            className={`regionsMap ${
                                                indexIsEven ? 'regionsMapLeft' : 'regionsMapRight'
                                            }`}
                                        />
                                    </div>
                                    {indexIsEven && <hr className="regionsDivider" />}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )}

                <Divider size={3} orientation="horizontal" />
            </ContainerRegions>
        </Layout>
    )
}

export default IndexPage
