import styled from 'styled-components'

export const BannerCarouselStyle = styled.div`
    position: relative;
    margin: auto;

    cursor: grab;

    .carouselBannerContent {
        position: absolute;
        bottom: 40px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: auto;

        .carrousel-item-content {
            margin: 0 auto;

            background: rgba(211, 35, 39, 0.71);
            padding: 5px 20px;
            margin-bottom: 5px;
        }

        .carouselBannerBtn {
            padding: 8px 20px;
        }
    }

    .imageBackgroundCarrousel {
    }

    .carousel-dotGroup {
        position: absolute;
        bottom: 20px;
        right: 0;
        left: 0;
    }

    @media only screen and (max-width: 798px) {
        .carouselBannerContent {
            h2 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
            }
            .carouselContentMarginB {
                padding: 5px 10px;
            }
        }
    }
`

export const SampleCarouselStyle = styled.div`
    position: relative;
    margin: 30px auto;

    .carousel__inner-slide {
        width: auto;
        height: auto;
        max-width: 250px;
        max-height: 140px;
    }

    .carouselButtonBack,
    .carouselButtonNext {
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        :focus {
            outline: none;
        }

        .carouselIcon {
            fill: #db1919;
            font-size: 30px;
            font-weight: 600;
            :hover {
                fill: #de2e2e;
            }
        }
    }

    @media only screen and (max-width: 798px) {
        .sampleCarouselItem {
            width: 90%;
            height: 90%;
            min-width: unset;
            min-height: unset;
        }
    }
`

export const CatalogCarouselStyle = styled.div`
    position: relative;
    margin: 0 0 180px;
    height: 120px;

    .catalogCarouselItem {
        width: 100vw;
        height: 300px;
    }

    .carousel__inner-slide {
        width: auto;
        height: auto;
        max-width: 750px;
        max-height: 300px;
    }

    .carouselButtonBack,
    .carouselButtonNext {
        position: absolute;
        top: 130%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        :focus {
            outline: none;
        }
        .carouselIcon {
            fill: #db1919;
            font-size: 30px;
            font-weight: 600;
            :hover {
                fill: #de2e2e;
            }
        }
    }

    .carousel-dotGroup {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        button {
            margin: 0 5px;
            height: 15px;
            border-radius: 50%;
        }

        .carousel__dot--selected {
            background-color: #de2e2e;
        }
    }
`
