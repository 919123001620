import { useEffect, useState } from 'react'

const useWindowSize = () => {
    let defaultWidth = 1200

    if (typeof window !== `undefined`) {
        defaultWidth = window.innerWidth
    }

    const [dimension, setDimension] = useState(defaultWidth)

    useEffect(() => {
        const handler = () => setDimension(window.innerWidth)

        window.addEventListener(`resize`, handler)
        return () => window.removeEventListener(`resize`, handler)
    }, [])

    return dimension
}

export default useWindowSize
