import styled from 'styled-components'

export const ItemDiamondStyle = styled.div`
    margin: 0px 0px 40px;

    flex-grow: 0;
    flex-basis: 20%;

    :hover {
        cursor: pointer;
        .categoriesDiamond {
            background: #c4c0c0 !important;
        }
    }

    .categoriesDiamond {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        width: 70px;
        height: 70px;
        background: #f0ebeb;
        transform: rotate(45deg);

        margin-bottom: 25px;
        .categoriesIcon {
            width: 32px !important;
            height: 32px !important;
            transform: rotate(-45deg);
        }
    }

    p {
        font-weight: bold;
    }

    @media only screen and (max-width: 798px) {
        flex-basis: 33%;
    }
`
